<template>
    <div class="section">
        <div class="container">
            <h1>Users</h1>

            <div class="tile">
                <o-input type="text" v-model="filter" placeholder="Type here to search..."></o-input>
            </div>
            <hr />
            <div class="columns">
                <div>
                    <o-table :data="filteredUsers">
                        <o-table-column field="Name" label="Name" v-slot="props">
                            <router-link :to="{  name: 'manageUser', params: { uid: props.row.Id } }">{{props.row.Name}}</router-link>
                        </o-table-column>
                        <o-table-column field="Email" label="Email" v-slot="props">
                            {{ props.row.Email }}
                        </o-table-column>
                    </o-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    export default {
        props: {},
        emits: [],
        setup() {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            const filter = ref("");
            const users = ref([]);

            const getUserList = async () => {
                try {
                    var response = await $http.get("/api/user/admin/list");
                    users.value = response.data;
                    filteredUsers.value = response.data;
                } catch (err) {
                    $error("Failed to login.");
                }
            }

            onMounted(async () => {
                await getUserList();
            });

            const filteredUsers = computed(() => {
                return users.value.filter((option) => {
                    if (option.Name && (option.Name.toString().toLowerCase().indexOf(filter.value.toLowerCase()) >= 0)) {
                        return option
                            .Name
                            .toString()
                            .toLowerCase()
                            .indexOf(filter.value.toLowerCase()) >= 0;
                    } else if (option.Email && (option.Email.toString().toLowerCase().indexOf(filter.value.toLowerCase()) >= 0)) {
                        return option
                            .Email
                            .toString()
                            .toLowerCase()
                            .indexOf(filter.value.toLowerCase()) >= 0;
                    }
                });
            });

            return {
                filter,
                users,
                getUserList,
                filteredUsers
            }
        }
    }
</script>